<template>
  <div class="typeDialog">
    <el-dialog width="344px"
      top="30vh"
      :visible.sync="dialogFormVisible.show">
      <div slot="title">{{title}}</div>
      <slot v-bind:data="formData"></slot>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="dialogFormVisible.show = false">取 消</el-button>
        <el-button type="primary"
          @click="$emit('submit')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'TypeDialog',
    props: {
      title: {
        type: String,
        required: true,
      },
      dialogFormVisible: {
        type: Object,
        required: true,
      },
      formData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formLabelWidth: '120px',
      };
    },
  };
</script>

<style lang="less">
  .typeDialog {
    .el-dialog {
      border-radius: 5px;
      .el-dialog__header {
        border-bottom: 1px solid #ddd;
        .el-dialog__title {
          font-weight: 550;
          color: #333333;
          font-size: 16px;
        }
      }
      .el-dialog__body {
        padding: 20px;
      }
      .el-dialog__footer {
        background: #f8f8f8;
        padding-bottom: 10px;
        border-radius: 5px;
        font-size: 14px;
      }
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 14px;
    }
    .el-form-item:last-child {
      margin-bottom: 0px;
    }
  }
</style>
