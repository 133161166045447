<template>
  <div class="attrDetail">
    <div class="empty" v-if="Object.keys(formData).length===0">暂无数据</div>
    <div class="attrDetail__form" v-else>
      <h2>属性详情</h2>
     <el-form :model="ruleForm"
        :rules="rules"
        label-position="left"
        :hide-required-asterisk="true"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="属性编号"
          prop="name">
          {{ ruleForm.code }}
        </el-form-item>
        <el-form-item label="属性名称"
          prop="name">
          <el-input size="mini"
            :disabled="true"
            @blur="putAtrr"
            v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="属性分类"
          prop="type">
          <el-radio-group v-model="ruleForm.type" :disabled="false" @change="putAtrr">
            <el-radio :label="0">参数属性</el-radio>
            <el-radio :label="1">标准编码</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关键字搜索"
          prop="isSearchable">
          <el-radio-group v-model="ruleForm.isSearchable" :disabled="false" @change="putAtrr">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="筛选条件"
          prop="isFilterable">
          <el-radio-group v-model="ruleForm.isFilterable" :disabled="false" @change="putAtrr">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="筛选方式"
          v-show="ruleForm.isFilterable"
          prop="filterMode">
          <el-radio-group v-model="ruleForm.filterMode" :disabled="false" @change="putAtrr">
            <el-radio :label="0">数值范围</el-radio>
            <el-radio :label="1">下拉选择</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['formData'],
    data() {
      return {
        ruleForm: {},
        rules: {
          type: [
            { required: true, message: '请选择属性分类', trigger: 'change' },
          ],
          name: [
            { required: true, message: '请填写属性名称', trigger: 'blur' },
          ],
          isSearchable: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          isFilterable: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          filterMode: [

          ],
        },
      };
    },
    watch: {
      formData: {
        handler(val) {
          // val.type = val.type;
          this.ruleForm = val;
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      putAtrr() {
        this.$store.dispatch('typesModule/putPropertyDefs', this.ruleForm);
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            return true;
          }
          return false;
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
  };
</script>

<style lang="less">
  .attrDetail {
    flex: 1;
    margin: 24px;
    background: #fff;
    .attrDetail__form {
      width: 532px;
      margin: 0 auto;
      h2 {
        padding: 24px 0 40px 0;
        width: 56px;
        height: 20px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 550;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #333333;
      }
      .el-form-item {
        margin-bottom: 15px;
      }
      .el-form-item__label,
      .el-form-item__content {
        line-height: 30px;
      }
    }
  }
</style>
