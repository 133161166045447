
  import Vue from 'vue';
  import TypeList from './TypeList.vue';
  import AttrDetail from './AttrDetail.vue';

  export default Vue.extend({
    components: {
      TypeList,
      AttrDetail,
    },
    data() {
      return {
        formData: {},
      };
    },
    methods: {
      selectedAttr(option) {
        this.formData = option;
      },
    },
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '类型管理',
          path: 'type',
        },
      ]);
    },
  });

