
  import Vue from 'vue';
  import { ProductCategoryItem } from '@/api/types';
  import * as api from '@/api';
  import TypeDialog from './TypeDialog.vue';

  export default Vue.extend({
    components: {
      TypeDialog,
    },
    data() {
      return {
        checkedList: [] as any,
        typeFilterWord: '',
        form: {
          typeName: '',
          attrName: '',
          toTypeId: '',
        },
        currentType: {} as ProductCategoryItem,
        selectedType: {} as ProductCategoryItem,
        selectedAttr: {},
        selectedTypeIndex: 0,
        selectedAtrrIndex: 0,
        dialogFormVisible: {
          show: false,
        },
        typeDiaTitle: '添加类型',
        attrs: [
          {
            id: 0,
            name: '新建属性',
          },
        ],
        rules: {
          typeName: [
            { required: true, message: '请输入类型名称', trigger: 'blur' },
          ],
          attrName: [
            { required: true, message: '请输入属性名称', trigger: 'blur' },
          ],
          toTypeId: [
            { required: true, message: '请选择分类名称', trigger: 'change' },
          ],
        },
      };
    },
    watch: {
      dialogFormVisible: {
        handler(val) {
          if (!val.show) (this.$refs.typeDia as any).resetFields();
        },
        deep: true,
      },
    },
    computed: {
      types(): ProductCategoryItem[] {
        this.initData();
        return this.$store.state.typesModule.types;
      },
    },
    methods: {
      doArchive(a, b, c) {
        console.log(a, b, c);
      },
      initData() {
        const res = [...this.$store.state.typesModule.types];
        if (res.length > 0) {
          this.selectType(res[this.selectedTypeIndex], this.selectedTypeIndex);
        } else {
          (this.selectedType as any) = {};
          (this.selectedAttr as any) = {};
          this.$emit('selectedAttr', {});
        }
      },
      searchTypes() {
        this.$store.dispatch('typesModule/getProductCategory', this.typeFilterWord);
      },
      selectAttr(item, index) {
        this.selectedAttr = item;
        this.selectedAtrrIndex = index;
        this.$emit('selectedAttr', item);
      },
      selectType(item, index) {
        this.checkedList = [];
        this.selectedType = item;
        this.selectedTypeIndex = index;
        this.selectedType.properties.forEach((i) => {
          this.checkedList.push({
            checked: false,
            ...i,
          });
        });
        this.selectAttr(item.properties[0], 0);
      },
      openTypeDia(option, title) {
        this.dialogFormVisible.show = true;
        this.typeDiaTitle = title;
        this.form.typeName = '';
        if (option) {
          this.currentType = option;
          this.form.typeName = option.name;
          if (title === '复制类型') {
            this.form.typeName = '';
          }
        }
      },
      submit() {
        (this.$refs.typeDia as any).validate(async (valid) => {
          if (valid) {
            if (this.typeDiaTitle === '添加类型') {
              const res = this.$store.dispatch('typesModule/putProductCategory', this.form);
              res.then((response) => {
                console.log(response);
                if (api.error(response)) {
                  (this as any).$message({
                    type: 'error',
                    message: response.error.message,
                  });
                } else {
                  (this as any).$message({
                    type: 'success',
                    message: '添加成功',
                  });
                  this.dialogFormVisible.show = false;
                }
              });
            } else if (this.typeDiaTitle === '编辑类型') {
              const res = this.$store.dispatch('typesModule/modifyProductCategoryName', { id: this.currentType.id, name: this.form.typeName });
              res.then((response) => {
                if (response.error) {
                  (this as any).$message({
                    type: 'error',
                    message: response.error.message,
                  });
                  return;
                }
                this.dialogFormVisible.show = false;
              });
            } else if (this.typeDiaTitle === '添加属性') {
              const res = this.$store.dispatch('typesModule/putProductProperties', { categoryId: this.selectedType.id, propertyName: this.form.attrName });
              res.then((response) => {
                if (response.error) {
                  (this as any).$message({
                    type: 'error',
                    message: response.error.message,
                  });
                  return;
                }
                this.dialogFormVisible.show = false;
              });
            } else if (this.typeDiaTitle === '复制属性至') {
              const res = this.$store.dispatch('typesModule/copyProductProperties', { id: this.form.toTypeId, properties: this.checkedList.filter((item: any) => item.checked === true).map((i) => i.code) });
              res.then((response) => {
                if (!api.error(res)) {
                  (this as any).$message({
                    type: 'success',
                    message: '复制属性成功',
                  });
                  this.dialogFormVisible.show = false;
                } else {
                  (this as any).$message({
                    type: 'error',
                    message: response.error.message,
                  });
                }
              });
            } else {
              const res = this.$store.dispatch('typesModule/copyProductCategory', { sourceId: this.currentType.id, name: this.form.typeName });
              res.then((response) => {
                if (response.error) {
                  (this as any).$message({
                    type: 'error',
                    message: response.error.message,
                  });
                  return;
                }
                this.dialogFormVisible.show = false;
              });
            }
          }
        });
      },
      deleteType(id) {
        (this as any).$confirm('该类型下所有属性，将会一起删除，无法恢复', '你确定要删除该类型吗？', {
          confirmButtonText: '确定删除', cancelButtonText: '取消', customClass: 'messageError', iconClass: 'el-icon-question iconRed',
        })
          .then(async () => {
            this.$store.dispatch('typesModule/deleteProductCategory', id);
          });
      },
      deleteAttrP() {
        const res = this.$store.dispatch('typesModule/deleteProductProperties', {
          id: this.selectedType.id,
          properties: this.checkedList.filter((item: any) => item.checked === true).map((i) => i.code),
        });
        res.then((response) => {
          if (response.error) {
            this.$message({
              message: response.error.message,
              type: 'error',
            });
          }
        });
      },
      deleteAttr(item) {
        delete item.checked;
        const res = this.$store.dispatch('typesModule/deleteProductProperties', {
          id: this.selectedType.id,
          properties: [item.code],
        });
        res.then((response) => {
          if (response.error) {
            this.$message({
              message: response.error.message,
              type: 'error',
            });
          }
        });
      },
    },
    created() {
      this.$store.dispatch('typesModule/getProductCategory');// 获取类型列表
    },
  });
